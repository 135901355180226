import React from 'react';
import Helmet from 'react-helmet';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../../layout/layout';
import config from '../../../data/SiteConfig';
import { Container, Section, Columns, Column, Content, Button, Breadcrumb, BreadcrumbItem } from 'bloomer';

export default class extends React.Component {

  render() {
    const people = this.props.data.people.edges;
    const bg = require('../../../content/images/ensemble/dark-inventions-banner.jpg');

    return (
      <Layout>
        <Helmet title={`People | ${config.siteTitle}`} />
        <Section className="has-navbar-padding">
          <Container>
            <hr />
            <Breadcrumb>
              <ul>
                <BreadcrumbItem>
                  <Link to="/">Home</Link>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <Link to="/about/">About</Link>
                </BreadcrumbItem>
                <BreadcrumbItem isActive>
                  <a>People</a>
                </BreadcrumbItem>
              </ul>
            </Breadcrumb>
            <h1 class="title is-1">People</h1>
            <hr />
            <Columns isMultiline isMobile isDesktop>
              {people.map(({ node }) => {
                return (
                  <Column isSize={{ mobile: 6, tablet: 4, desktop: 3 }}>
                    <Content>
                      <Link to={node.fields.slug}>
                        {node.frontmatter.photo ? (
                          <Img
                            fluid={node.frontmatter.photo.childImageSharp.fluid}
                          />
                        ) : (
                          <img src="//placehold.it/300x300" />
                        )}
                      </Link>
                      <h2 class="title is-4">
                        <Link to={node.fields.slug}>
                          {node.frontmatter.firstname}{" "}
                          {node.frontmatter.surname}
                        </Link>
                      </h2>
                      <p className="subtitle is-6">{node.frontmatter.job}</p>
                      <p>{node.excerpt}</p>
                      {node.frontmatter.links
                        ? node.frontmatter.links.map((link) => {
                            return (
                              <a href={link.url}>
                                <Button
                                  size="small"
                                  color="dark"
                                  style={{ marginRight: "5px" }}
                                >
                                  {link.label}
                                </Button>
                              </a>
                            );
                          })
                        : null}
                    </Content>
                  </Column>
                );
              })}
            </Columns>
          </Container>
        </Section>
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query {
    people: allMarkdownRemark(
      filter: { fields: {
        template: {
          eq: "people"
        }
      }},
      sort: {
        fields:[frontmatter___surname, frontmatter___firstname], order:ASC
      }
    ) {
      edges {
        node {
          id
          excerpt
          fields {
            slug
          }
          frontmatter {
            firstname
            surname
            job
            photo {
              childImageSharp {
                fluid(
                  maxWidth:300,
                  maxHeight:300,
                  quality:80,
                ) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            links {
              label
              url
            }
          }
        }
      }
    }
  }
`;
